<template>
  <t-body>
    <t-sidebar>
      <t-loading v-if="$fetchState.pending" />

      <div v-else>
        <h3 v-if="applicant.user" class="mt-2 mb-3">
          {{ applicant.user.fullname }}
        </h3>

        <div>
          <h4 class="mb-2 text--grey">
            {{ $t('fields.actions') }}
          </h4>
        </div>
      </div>
    </t-sidebar>

    <t-page>
      <t-loading v-if="$fetchState.pending" />
      <div v-else>
        <t-form-card v-if="applicant.user">
          <t-grid>
            <t-grid-row>
              <t-grid-cell :size="2">
                <t-form-element>{{ $t('fields.id') }}</t-form-element>
                <t-input v-model="data.id" readonly />
              </t-grid-cell>

              <t-grid-cell :size="5">
                <t-form-element>{{ $t('fields.first_name') }}</t-form-element>
                <t-input v-model="data.user.firstname" readonly />
              </t-grid-cell>

              <t-grid-cell :size="5">
                <t-form-element>{{ $t('fields.last_name') }}</t-form-element>
                <t-input v-model="data.user.lastname" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.email_address') }}</t-form-element>
                <t-input v-model="data.user.email" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.phonenumber') }}</t-form-element>
                <t-input v-model="data.meta.phonenumber" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell :size="3">
                <t-form-element>{{ $t('fields.gender') }}</t-form-element>
                <t-input v-model="data.user.gender" readonly />
              </t-grid-cell>

              <t-grid-cell :size="3">
                <t-form-element>{{ $t('fields.verified_account') }}</t-form-element>
                <t-input v-model="data.user.verified" readonly />
              </t-grid-cell>

              <t-grid-cell :size="3">
                <t-form-element>{{ $t('fields.accepted_tos') }}</t-form-element>
                <t-input v-model="data.tos_accepted" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.available') }}</t-form-element>
                <t-input v-model="data.available" readonly />
              </t-grid-cell>

              <t-grid-cell>
                <t-form-element>{{ $t('fields.looking') }}</t-form-element>
                <t-input v-model="data.looking" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.date_of_birth') }}</t-form-element>
                <t-input v-model="data.date_of_birth" readonly />
              </t-grid-cell>

              <t-grid-cell :size="3">
                <t-form-element>{{ $t('fields.age') }}</t-form-element>
                <t-input v-model="data.age" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.address.address') }}</t-form-element>
                <t-input :value="data.address" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.occupation') }}</t-form-element>
                <t-input :value="data.occupation" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.organization') }}</t-form-element>
                <t-input :value="data.organization" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.created_at') }}</t-form-element>
                <t-input :value="data.created_at" readonly />
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-form-element>{{ $t('fields.graduation_type') }}</t-form-element>
                <t-input :value="data.graduation_type" readonly />
              </t-grid-cell>
            </t-grid-row>
          </t-grid>
        </t-form-card>

        <t-form-card v-if="applicant.legal_contact" class="mt-4">
          <h4 class="mb-2 text--grey">
            {{ $t('fields.guardian.details') }}
          </h4>

          <t-grid-row>
            <t-grid-cell>
              <t-form-element>{{ $t('fields.guardian.name') }}</t-form-element>
              <t-input :value="data.legal_contact.fullName" readonly />
            </t-grid-cell>
            <t-grid-cell>
              <t-form-element>{{ $t('fields.guardian.email') }}</t-form-element>
              <t-input :value="data.legal_contact.email" readonly />
            </t-grid-cell>
            <t-grid-cell>
              <t-form-element>{{ $t('fields.guardian.gender') }}</t-form-element>
              <t-input :value="data.legal_contact.gender" readonly />
            </t-grid-cell>
          </t-grid-row>
        </t-form-card>

        <t-form-card class="mt-4">
          <h4 class="mb-2 text--grey">
            {{ $t('pages.organization.pings.heading') }}
          </h4>
          <index-table :query="pingQuery" :fields="pingFields" />
        </t-form-card>
      </div>
    </t-page>
  </t-body>
</template>

<script>
import Applicant from '~/models/Applicant';
import Ping from '~/models/Ping';
import TFormCard from '~/components/TFormCard';
import TLoading from '~/components/TLoading';
import IndexTable from '~/pages/shared/pings/partials/IndexTable';

export default {
  components: {
    TFormCard,
    TLoading,
    IndexTable,
  },

  data() {
    return {
      applicant: null,
      data: null,
      query: Applicant,
      pingFields: [],
    };
  },

  async fetch() {
    this.applicant = await this.query.$find(this.$route.params.id);

    this.data = this.getData(this.applicant);
  },

  computed: {
    pingQuery() { return () => new Ping(); },
  },

  methods: {
    getData(applicant = null) {
      return {
        id: applicant?.id,

        legal_contact: {
          fullName:
            applicant?.legal_contact?.firstname || applicant?.legal_contact?.lastname
              ? `${applicant?.legal_contact?.firstname} ${applicant?.legal_contact?.lastname}`
              : this.$t('fields.unknown'),

          email: applicant?.legal_contact?.email || this.$t('fields.unknown'),
          gender: applicant?.legal_contact?.gender || this.$t('fields.unknown'),
        },

        address: applicant?.address?.formatted?.one_line ?? 'Unknown',

        created_at: this.$dayjs(applicant?.created_at).format('L @ H:m:s'),

        tos_accepted: applicant?.tos_accepted ? this.$t('fields.yes') : this.$t('fields.no'),

        available: applicant?.available ? this.$t('fields.yes') : this.$t('fields.no'),
        looking: applicant?.looking ? this.$t('fields.yes') : this.$t('fields.no'),

        date_of_birth: applicant?.date_of_birth
          ? this.$dayjs(applicant.date_of_birth).format('L')
          : this.$t('fields.unknown'),

        age: applicant?.date_of_birth
          ? this.$dayjs(new Date()).diff(this.$dayjs(applicant.date_of_birth), 'year')
          : this.$t('fields.unknown'),

        graduation_type: applicant?.graduation_type?.translation ?? this.$t('fields.unknown'),

        user: {
          email: applicant?.user?.email,
          firstname: applicant?.user?.firstname,
          lastname: applicant?.user?.lastname,
          fullname: `${applicant?.user?.firstname} ${applicant?.user?.lastname}`,
          gender: applicant?.user?.gender,
          verified: applicant?.user?.verified ? this.$t('fields.yes') : this.$t('fields.no'),
        },

        occupation: applicant?.occupation?.name ?? this.$t('fields.unknown'),
        organization: applicant?.organization?.name ?? this.$t('fields.unknown'),

        meta: {
          phonenumber: applicant?.meta?.phonenumber ?? this.$t('fields.unknown'),
        },
      };
    },
  },
};
</script>
